import { Title } from '@solidjs/meta';
import { useLocation, useSearchParams } from '@solidjs/router';
import { AuthFlow } from '../../partials/auth/auth';

export default function Auth() {
	const [params] = useSearchParams<Record<string, string>>();
	const location = useLocation<{ fromCard?: boolean }>();
	const { redirect, redirectUrl, ...data } = params ?? {};

	return (
		<>
			<Title>Join or log in | Troon</Title>
			<div class="w-dvw max-w-full overflow-clip rounded bg-white shadow-xl sm:size-fit sm:max-w-4xl">
				<AuthFlow
					data={data}
					redirect={redirect ?? redirectUrl}
					descriptions={location.state?.fromCard ? cardDescriptions : undefined}
				/>
			</div>
		</>
	);
}

const cardDescriptions = {
	'/auth/': () => <>Enter the email associated with your Troon Card/Troon Rewards Account to log in.</>,
};
